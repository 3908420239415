'use client';
import React, { ReactNode } from 'react';
import { Theme } from '@radix-ui/themes';
import { SessionProvider } from 'next-auth/react';

import '@radix-ui/themes/styles.css';

interface Props {
  children: ReactNode;
}

const Providers = (props: Props) => {
  return (
    <SessionProvider>
      <Theme>{props.children}</Theme>
    </SessionProvider>
  );
};

export default Providers;
